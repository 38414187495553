<template>
  <div>
    <v-container>
      <v-toolbar>
        <v-toolbar-title> Super Admin Tools </v-toolbar-title>
        <v-spacer> </v-spacer>
        <admin-toolbar-menu :menu-items="menuItems" />
      </v-toolbar>
    </v-container>
    <div v-if="$route.name === 'SuperAdminIndex'">
      <v-container>
        <v-row>
          <v-col> Welcome, {{ user.name }}! </v-col>
        </v-row>
      </v-container>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AdminToolbarMenu from '../../components/admin/general/AdminToolbarMenu.vue'

export default {
  name: 'SuperAdminIndex',
  components: {
    AdminToolbarMenu
  },
  data() {
    return {
      menu: false,
      menuItems: [
        {
          title: 'Home',
          link: 'SuperAdminIndex'
        },
        {
          title: 'Group Manager',
          link: 'SuperAdminGroupManager'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.userData
    })
  }
}
</script>

<style></style>
